import payload_plugin_fXYgwMpUxC from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.34.2/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_2rfIbFz0J1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_nlg6izaispkpnfiazufwqmsqjm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9nyC2XwDEl from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_nlg6izaispkpnfiazufwqmsqjm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_PsquU5izW6 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_nlg6izaispkpnfiazufwqmsqjm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_MKzn1xr2Dc from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_nlg6izaispkpnfiazufwqmsqjm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_AOMNoAslpm from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_nlg6izaispkpnfiazufwqmsqjm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_A4ibxoo777 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_nlg6izaispkpnfiazufwqmsqjm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_6AWSIiKXtv from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_nlg6izaispkpnfiazufwqmsqjm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_6vtXQiI79c from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.34.2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/front/.nuxt/components.plugin.mjs";
import prefetch_client_BYO0MrnHzX from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_nlg6izaispkpnfiazufwqmsqjm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_V15E2mBL1U from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.34.2_vite@6.0.11_@types+node@22.13.1_jiti@2.4.2_sas_vnvqwq7cgs2meuwijzlhdqynwq/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollspy_client_mRTZl1UJDi from "/vercel/path0/apps/front/src/plugins/scrollspy.client.js";
import firebase_client_lOvfsw6Ppv from "/vercel/path0/apps/front/src/plugins/firebase.client.ts";
import form_fhHqzO4Fku from "/vercel/path0/apps/front/src/plugins/form.js";
import loader_GbMfCzpAFz from "/vercel/path0/apps/front/src/plugins/loader.ts";
import scroll_to_directive_mvfaQkBu2F from "/vercel/path0/apps/front/src/plugins/scroll-to-directive.js";
import scroll_to_helper_8mimme1nn3 from "/vercel/path0/apps/front/src/plugins/scroll-to-helper.ts";
import scrollToHash_client_JvX8Qke8oP from "/vercel/path0/apps/front/src/plugins/scrollToHash.client.js";
import toggle_collapse_directive_cygon8PgEz from "/vercel/path0/apps/front/src/plugins/toggle-collapse-directive.js";
import vee_validate_client_45gWhfAHXv from "/vercel/path0/apps/front/src/plugins/vee-validate.client.js";
import vue_click_away_QoBmAA3ck9 from "/vercel/path0/apps/front/src/plugins/vue-click-away.js";
import vue_gtm_client_3YPaNVLi9H from "/vercel/path0/apps/front/src/plugins/vue-gtm.client.js";
import vue_social_sharing_glaVIJc3fZ from "/vercel/path0/apps/front/src/plugins/vue-social-sharing.js";
import wp_api_provider_plugin_GTJSRz7MzG from "/vercel/path0/apps/front/src/plugins/wp-api-provider.plugin.js";
import youtube_client_9MyVUG7wyS from "/vercel/path0/apps/front/src/plugins/youtube.client.ts";
export default [
  payload_plugin_fXYgwMpUxC,
  revive_payload_client_2rfIbFz0J1,
  unhead_9nyC2XwDEl,
  router_PsquU5izW6,
  payload_client_MKzn1xr2Dc,
  navigation_repaint_client_AOMNoAslpm,
  check_outdated_build_client_A4ibxoo777,
  chunk_reload_client_6AWSIiKXtv,
  plugin_vue3_6vtXQiI79c,
  components_plugin_KR1HBZs4kY,
  prefetch_client_BYO0MrnHzX,
  plugin_V15E2mBL1U,
  scrollspy_client_mRTZl1UJDi,
  firebase_client_lOvfsw6Ppv,
  form_fhHqzO4Fku,
  loader_GbMfCzpAFz,
  scroll_to_directive_mvfaQkBu2F,
  scroll_to_helper_8mimme1nn3,
  scrollToHash_client_JvX8Qke8oP,
  toggle_collapse_directive_cygon8PgEz,
  vee_validate_client_45gWhfAHXv,
  vue_click_away_QoBmAA3ck9,
  vue_gtm_client_3YPaNVLi9H,
  vue_social_sharing_glaVIJc3fZ,
  wp_api_provider_plugin_GTJSRz7MzG,
  youtube_client_9MyVUG7wyS
]