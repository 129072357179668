<template lang="pug">
#backgroundColor(ref="content" :style="{ backgroundColor: isEnabled ? backgroundColor : 'transparent' }")
  slot
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { useRoute } from 'vue-router';

const colors = [
  '#3f2a89',
  '#3f2a89 ',
  // '#55266c',
  '#6b214f',
  '#000000',
  // '#030b22',
  '#091940',
  // '#0b1e48',
  '#1f435d',
  '#70e2b3',
  '#70e2b3 ',
  // '#75dabf',
  '#75d3d0',
  '#77b3e9',
  '#537df7',
  '#3b58fc',
  '#212529',
];

const route = useRoute();
const content = ref<HTMLElement | null>(null);
const backgroundColor = ref(colors[0]);

// ✅ Only these pages will enable the component
const enabledPages = ['/', '/profile', '/settings'];

const isEnabled = computed(() => enabledPages.includes(route.path));

function hexToRgb(hex: string) {
  const bigint = parseInt(hex.replace('#', ''), 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  };
}

function blendColors(
  color1: { r: number; g: number; b: number },
  color2: { r: number; g: number; b: number },
  fraction: number,
) {
  return {
    r: Math.round(color1.r + (color2.r - color1.r) * fraction),
    g: Math.round(color1.g + (color2.g - color1.g) * fraction),
    b: Math.round(color1.b + (color2.b - color1.b) * fraction),
  };
}

function handleScroll() {
  if (!content.value) return;

  const scrollPosition = window.scrollY;
  const totalHeight = content.value.scrollHeight - window.innerHeight;

  let scrollFraction = totalHeight > 0 ? scrollPosition / totalHeight : 0;
  scrollFraction = Math.max(0, Math.min(1, scrollFraction));

  const normalized = scrollFraction * (colors.length - 1);
  let index = Math.floor(normalized);
  let fraction = normalized - index;

  if (index >= colors.length - 1) {
    index = colors.length - 1;
    fraction = 0;
  }

  const c1 = hexToRgb(colors[index]);
  const c2 = hexToRgb(colors[Math.min(index + 1, colors.length - 1)]);
  const blended = blendColors(c1, c2, fraction);

  backgroundColor.value = `rgb(${blended.r}, ${blended.g}, ${blended.b})`;
}

onMounted(() => {
  if (isEnabled.value) {
    window.addEventListener('scroll', handleScroll);
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});

// React to route changes
watch(isEnabled, (newVal) => {
  if (newVal) {
    window.addEventListener('scroll', handleScroll);
  } else {
    backgroundColor.value = colors[0];
    window.removeEventListener('scroll', handleScroll);
  }
});
</script>
