
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93QtMPtZSI7vMeta } from "/vercel/path0/apps/front/src/pages/articles/[slug].vue?macro=true";
import { default as index4GtCwOhJvwMeta } from "/vercel/path0/apps/front/src/pages/articles/index.vue?macro=true";
import { default as _91slug_93elUDGsjEUEMeta } from "/vercel/path0/apps/front/src/pages/beliefs/[slug].vue?macro=true";
import { default as index2BUXtn8Hs9Meta } from "/vercel/path0/apps/front/src/pages/beliefs/index.vue?macro=true";
import { default as campaignsXZ8putBwHuMeta } from "/vercel/path0/apps/front/src/pages/campaigns.vue?macro=true";
import { default as contact_45usvNFgWlULX9Meta } from "/vercel/path0/apps/front/src/pages/contact-us.vue?macro=true";
import { default as discover1SQWUqbY3iMeta } from "/vercel/path0/apps/front/src/pages/discover.vue?macro=true";
import { default as _91slug_93VFjAwWVpGJMeta } from "/vercel/path0/apps/front/src/pages/elevenpoints/[slug].vue?macro=true";
import { default as indexRutPcguWcNMeta } from "/vercel/path0/apps/front/src/pages/elevenpoints/index.vue?macro=true";
import { default as exhibitionsNRSkcksociMeta } from "/vercel/path0/apps/front/src/pages/exhibitions.vue?macro=true";
import { default as indexDxaxxOHueXMeta } from "/vercel/path0/apps/front/src/pages/index.vue?macro=true";
import { default as indexV26SX41KYRrQMeta } from "/vercel/path0/apps/front/src/pages/indexV2.vue?macro=true";
import { default as nuestros_45clientesHFAakKAxISMeta } from "/vercel/path0/apps/front/src/pages/nuestros-clientes.vue?macro=true";
import { default as pocfS9o2evYOjMeta } from "/vercel/path0/apps/front/src/pages/poc.vue?macro=true";
import { default as indexa0p2nCB2aVMeta } from "/vercel/path0/apps/front/src/pages/stopww3/index.vue?macro=true";
import { default as introductionX8NtaCKHueMeta } from "/vercel/path0/apps/front/src/pages/stopww3/introduction.vue?macro=true";
import { default as the_45campaign5mSdxWyI49Meta } from "/vercel/path0/apps/front/src/pages/stopww3/the-campaign.vue?macro=true";
import { default as the_45critical_45need_45for_45peacezpfIv1JJAGMeta } from "/vercel/path0/apps/front/src/pages/stopww3/the-critical-need-for-peace.vue?macro=true";
import { default as the_45prophecyRZ9gkEC6p5Meta } from "/vercel/path0/apps/front/src/pages/stopww3/the-prophecy.vue?macro=true";
import { default as submit_45your_45question9q5UN8955TMeta } from "/vercel/path0/apps/front/src/pages/submit-your-question.vue?macro=true";
import { default as terms_45of_45service32WdWTg674Meta } from "/vercel/path0/apps/front/src/pages/terms-of-service.vue?macro=true";
import { default as testDbI0tyS0wFMeta } from "/vercel/path0/apps/front/src/pages/test.vue?macro=true";
import { default as the_45messiah_45has_45comeoGcxS7xUZcMeta } from "/vercel/path0/apps/front/src/pages/the-messiah-has-come.vue?macro=true";
import { default as topics_45indexPvT2vLWQUbMeta } from "/vercel/path0/apps/front/src/pages/topics-index.vue?macro=true";
import { default as use_45of_45cookiesBW7cdhzmLiMeta } from "/vercel/path0/apps/front/src/pages/use-of-cookies.vue?macro=true";
export default [
  {
    name: "articles-slug",
    path: "/articles/:slug()",
    component: () => import("/vercel/path0/apps/front/src/pages/articles/[slug].vue")
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/vercel/path0/apps/front/src/pages/articles/index.vue")
  },
  {
    name: "beliefs-slug",
    path: "/beliefs/:slug()",
    meta: _91slug_93elUDGsjEUEMeta || {},
    component: () => import("/vercel/path0/apps/front/src/pages/beliefs/[slug].vue")
  },
  {
    name: "beliefs",
    path: "/beliefs",
    component: () => import("/vercel/path0/apps/front/src/pages/beliefs/index.vue")
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/vercel/path0/apps/front/src/pages/campaigns.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/vercel/path0/apps/front/src/pages/contact-us.vue")
  },
  {
    name: "discover",
    path: "/discover",
    component: () => import("/vercel/path0/apps/front/src/pages/discover.vue")
  },
  {
    name: "elevenpoints-slug",
    path: "/elevenpoints/:slug()",
    meta: _91slug_93VFjAwWVpGJMeta || {},
    component: () => import("/vercel/path0/apps/front/src/pages/elevenpoints/[slug].vue")
  },
  {
    name: "elevenpoints",
    path: "/elevenpoints",
    meta: indexRutPcguWcNMeta || {},
    component: () => import("/vercel/path0/apps/front/src/pages/elevenpoints/index.vue")
  },
  {
    name: "exhibitions",
    path: "/exhibitions",
    component: () => import("/vercel/path0/apps/front/src/pages/exhibitions.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/apps/front/src/pages/index.vue")
  },
  {
    name: "indexV2",
    path: "/indexV2",
    component: () => import("/vercel/path0/apps/front/src/pages/indexV2.vue")
  },
  {
    name: "nuestros-clientes",
    path: "/nuestros-clientes",
    component: () => import("/vercel/path0/apps/front/src/pages/nuestros-clientes.vue")
  },
  {
    name: "poc",
    path: "/poc",
    component: () => import("/vercel/path0/apps/front/src/pages/poc.vue")
  },
  {
    name: "stopww3",
    path: "/stopww3",
    meta: indexa0p2nCB2aVMeta || {},
    component: () => import("/vercel/path0/apps/front/src/pages/stopww3/index.vue")
  },
  {
    name: "stopww3-introduction",
    path: "/stopww3/introduction",
    meta: introductionX8NtaCKHueMeta || {},
    component: () => import("/vercel/path0/apps/front/src/pages/stopww3/introduction.vue")
  },
  {
    name: "stopww3-the-campaign",
    path: "/stopww3/the-campaign",
    component: () => import("/vercel/path0/apps/front/src/pages/stopww3/the-campaign.vue")
  },
  {
    name: "stopww3-the-critical-need-for-peace",
    path: "/stopww3/the-critical-need-for-peace",
    meta: the_45critical_45need_45for_45peacezpfIv1JJAGMeta || {},
    component: () => import("/vercel/path0/apps/front/src/pages/stopww3/the-critical-need-for-peace.vue")
  },
  {
    name: "stopww3-the-prophecy",
    path: "/stopww3/the-prophecy",
    meta: the_45prophecyRZ9gkEC6p5Meta || {},
    component: () => import("/vercel/path0/apps/front/src/pages/stopww3/the-prophecy.vue")
  },
  {
    name: "submit-your-question",
    path: "/submit-your-question",
    component: () => import("/vercel/path0/apps/front/src/pages/submit-your-question.vue")
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    component: () => import("/vercel/path0/apps/front/src/pages/terms-of-service.vue")
  },
  {
    name: "test",
    path: "/test",
    meta: testDbI0tyS0wFMeta || {},
    component: () => import("/vercel/path0/apps/front/src/pages/test.vue")
  },
  {
    name: "the-messiah-has-come",
    path: "/the-messiah-has-come",
    component: () => import("/vercel/path0/apps/front/src/pages/the-messiah-has-come.vue")
  },
  {
    name: "topics-index",
    path: "/topics-index",
    component: () => import("/vercel/path0/apps/front/src/pages/topics-index.vue")
  },
  {
    name: "use-of-cookies",
    path: "/use-of-cookies",
    component: () => import("/vercel/path0/apps/front/src/pages/use-of-cookies.vue")
  }
]