<template lang="pug">
component.button.gap-2.align-items-center(
  :is='isType'
  :text='text'
  isUnderLine='none'
  :fontWeight='fontWeight'
  :aria-disabled="ariaDisabled"
  :aria-label="ariaLabel || null"
  :tabindex="tabIndexValue"
  :linkColor="linkColor"
  :link="link"
  :class="allClasses"
  @click="preventDisabledClick"
)
  template(v-if='$slots.prevLabel' #prevLabel)
    slot(name='prevLabel' :text="text")
  template(v-if='$slots.icon' #icon)
    slot(name="icon")
</template>

<script lang="ts" setup>
import { defineProps, withDefaults } from 'vue';
import {
  type InteractiveButtonElement,
  defaultInteractiveButtonElement,
} from 'data-types-config';
import useButtonLogic from '../../composables/useButtonLogic';
const props = withDefaults(
  defineProps<Partial<InteractiveButtonElement>>(),
  defaultInteractiveButtonElement,
);

const {
  allClasses,
  ariaDisabled,
  tabIndexValue,
  preventDisabledClick,
  isType,
} = useButtonLogic(props);
</script>

<style lang="scss">
.button {
  $block: &;

  &#{&} {
    position: relative;
    display: flex;
    height: fit-content;
    width: unset;
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    pointer-events: unset;
  }

  &#{&} {
    &--disabled {
      cursor: not-allowed;
      pointer-events: unset;
      opacity: 0.5;
    }
  }
}

button,
input {
  border: unset;
  padding: unset;
  background-color: unset;
  height: auto;
}
</style>
