import load from 'load-script';
import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  const iframeAPIReady = new Promise<typeof YT>((resolve, reject) => {
    if (window.YT && window.YT.Player) {
      resolve(window.YT);
      return;
    }
    const protocol = window.location.protocol === 'http:' ? 'http:' : 'https:';
    load(`${protocol}//www.youtube.com/iframe_api`, (error) => {
      if (error) {
        console.error('YouTube API failed to load:', error);
        reject(error);
        return;
      }
    });
    window.onYouTubeIframeAPIReady = () => {
      if (window.YT) {
        resolve(window.YT);
      } else {
        reject(new Error('YouTube API did not load correctly.'));
      }
    };
  });

  // ✅ Provide `youtubeApi` globally using `nuxtApp.provide`
  nuxtApp.provide('youtubeApi', () => iframeAPIReady);
});
